import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="fixed left-1/2 top-4 z-50 w-11/12 max-w-6xl -translate-x-1/2 transform">
      {/* Bridge Structure Container */}
      <div className="relative overflow-hidden rounded-lg bg-brown-900/90 shadow-2xl backdrop-blur-sm">
        {/* Bridge Roof - Triangular shape */}
        <div className="absolute left-0 right-0 top-0 h-2 bg-gradient-to-b from-brown-800 to-transparent"></div>

        {/* Main Navigation Content */}
        <div className="relative px-6 py-4">
          <div className="flex items-center justify-between">
            {/* Logo Section */}
            <div className="flex-shrink-0">
              <Link to="/" className="text-xl font-bold text-white">
                Atawalk: Covered Bridges
              </Link>
            </div>

            {/* Navigation Links */}
            <div className="flex space-x-8">
              <Link
                to="/"
                className={`rounded-md px-3 py-2 text-sm font-medium transition-colors ${
                  location.pathname === '/'
                    ? 'bg-brown-700 text-white'
                    : 'text-white hover:bg-brown-700 hover:text-white'
                }`}
              >
                Realistic Generation
              </Link>
              <Link
                to="/leonardo"
                className={`rounded-md px-3 py-2 text-sm font-medium transition-colors ${
                  location.pathname === '/leonardo'
                    ? 'bg-brown-700 text-white'
                    : 'text-white hover:bg-brown-700 hover:text-white'
                }`}
              >
                Creative Generation
              </Link>
            </div>
          </div>
        </div>

        {/* Bridge Support Beams */}
        <div className="absolute bottom-0 left-0 flex w-full justify-between px-4">
          <div className="h-4 w-1 bg-brown-700"></div>
          <div className="h-4 w-1 bg-brown-700"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
