import React from 'react';

function StyleSelector({ onStyleChange, selectedStyle }) {
  const styles = [
    { id: 'burr-arch', name: 'Burr Arch Truss', description: 'Emphasizes strength and classic design' },
    { id: 'town-lattice', name: 'Town Lattice Truss', description: 'Offers a delicate and intricate appearance' },
    { id: 'howe', name: 'Howe Truss', description: 'Presents a robust and industrial feel' },
    { id: 'king-post', name: 'King Post Truss', description: 'Suggests simplicity and traditional craftsmanship' },
    { id: 'pratt', name: 'Pratt Truss', description: 'Conveys a balance between functionality and aesthetic appeal' },
    { id: 'queen-post', name: 'Queen Post Truss', description: 'Balances complexity with structural elegance' },
  ];

  return (
    <div className="space-y-4">
      <label className="mb-2 block text-sm font-medium text-white">
        Select Cover Style
      </label>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        {styles.map((style) => (
          <div
            key={style.id}
            onClick={() => onStyleChange(style.id)}
            className={`cursor-pointer rounded-lg border ${
              selectedStyle === style.id
                ? 'border-brown-700 border-2 bg-white/30 text-brown-700'
                : 'border-white/20 bg-white/10 hover:bg-white/20'
            } p-4 transition-colors duration-200`}
          >
            <h3 className="mb-1 text-lg font-medium text-white">{style.name}</h3>
            <p className="text-sm text-white/60">{style.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StyleSelector;
