import React, { useState } from 'react';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@radix-ui/react-collapsible';
import ImageUploader from '../components/ImageUploader';
import LeonardoParams from '../components/LeonardoParams';

function LeonardoPage() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [params, setParams] = useState({
    prompt: '',
    alchemy: true,
    contrastRatio: 0.5,
    guidance_scale: 7,
    height: 512,  // Default height
    width: 512,   // Default width
    num_images: 4,
    presetStyle: 'DYNAMIC',
    init_strength: 0.2,
    num_inference_steps: 55,
    photoRealStrength: 0.55,
    scheduler: 'LEONARDO',
    sd_version: 'v1_5',
    transparency: 'disabled',
    negative_prompt: 'Exclude any alterations to the existing bridge structure itself...',
    modelId: '5c232a9e-9061-4777-980a-ddc8e65647c6',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (file.size > 10 * 1024 * 1024) { // Example: limit file size to 5MB
          setError('Image size must be less than 10MB.');
          setUploadedImage(null);
          return;
        }
        setUploadedImage(file);
        setError('');
      };
      img.onerror = () => {
        setError('Invalid image file.');
        setUploadedImage(null);
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleGenerateImage = async () => {
    if (!uploadedImage) {
      setError('Please upload a valid image.');
      return;
    }

    setIsLoading(true); // Start loading
    try {
      const formData = new FormData();
      formData.append('image', uploadedImage);
      formData.append('prompt', params.prompt);
      
      // Add all params to formData
      Object.keys(params).forEach(key => {
        if (key !== 'prompt') {
          formData.append(key, JSON.stringify(params[key]));
        }
      });

      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      console.log('Sending request to:', apiUrl);
      console.log('Current hostname:', window.location.hostname);

      const response = await fetch(`${apiUrl}/api/generate-image`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate image');
      }

      const result = await response.json();
      setGeneratedImages(result.generatedImages);
      setError('');
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        stack: error.stack,
        name: error.name
      });
      setError(error.message);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="mx-auto max-w-4xl text-white">
      <div className="mb-8 text-center">
        <h1 className="text-4xl font-bold tracking-tight">Creative Bridge Cover Generator</h1>
        <p className="mt-2 text-xl text-white/60">Transform your bridge with AI-powered creativity</p>
      </div>

      {error && (
        <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <div className="space-y-6">
          <ImageUploader onFileChange={handleFileChange} />
          
          <div className="space-y-4">
            <div>
              <label className="mb-2 block text-sm font-medium">Creative Prompt</label>
              <textarea
                name="prompt"
                value={params.prompt}
                onChange={(e) => setParams(prev => ({ ...prev, prompt: e.target.value }))}
                placeholder="Describe how you want your covered bridge to look..."
                className="w-full rounded-lg border border-white/20 bg-white/10 p-4 text-white placeholder:text-white/40 focus:border-white/30 focus:ring-white/20"
                rows={4}
              />
            </div>

            <Collapsible>
              <CollapsibleTrigger className="flex w-full items-center justify-between rounded-lg border border-white/20 bg-white/10 p-4 text-left hover:bg-white/20">
                <span>Advanced Settings</span>
                <svg
                  className={`h-5 w-5 transform transition-transform ${isAdvancedOpen ? 'rotate-180' : ''}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </CollapsibleTrigger>
              <CollapsibleContent className="mt-4">
                <LeonardoParams params={params} setParams={setParams} />
              </CollapsibleContent>
            </Collapsible>

            <button
              onClick={handleGenerateImage}
              disabled={!uploadedImage || !params.prompt.trim() || isLoading}
              className="w-full rounded-lg bg-gradient-to-r from-purple-500 to-pink-500 px-5 py-3 text-center text-sm font-medium text-white hover:from-purple-600 hover:to-pink-600 focus:outline-none focus:ring-4 focus:ring-purple-300 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {isLoading ? 'Generating...' : 'Generate Creative Cover'}
            </button>
            {isLoading && (
              <div className="flex justify-center items-center mt-4">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-6">
          {uploadedImage && (
            <div className="overflow-hidden rounded-lg bg-white/10 p-4">
              <img
                src={URL.createObjectURL(uploadedImage)}
                alt="Uploaded Bridge"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          )}
          
          {generatedImages.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold">Generated Variations</h2>
              <div className="grid grid-cols-2 gap-4">
                {generatedImages.map((imageUrl, index) => (
                  <div key={index} className="relative overflow-hidden rounded-lg bg-white/10">
                    <img
                      src={imageUrl}
                      alt={`Generated ${index + 1}`}
                      className="w-full rounded-lg shadow-lg"
                    />
                    <a
                      href={imageUrl}
                      download={`bridge-cover-${index + 1}.jpg`}
                      className="absolute bottom-4 right-4 rounded-full bg-green-500 p-2 text-white hover:bg-green-600"
                    >
                      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
                      </svg>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeonardoPage;
