import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LeonardoPage from './pages/LeonardoPage';
import Navbar from './components/Navbar';
import './App.css';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-water-pattern bg-cover bg-fixed bg-center">
        <div className="min-h-screen bg-white/50">
          <Navbar />
          <div className="mx-auto w-11/12 max-w-6xl pt-24">
            <div className="rounded-lg bg-brown-900/70 p-6 shadow-xl backdrop-blur-sm">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/leonardo" element={<LeonardoPage />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
