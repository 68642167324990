import React from 'react';

function ImageUploader({ onFileChange, className = '' }) {
  return (
    <div className={`flex w-full items-center justify-center ${className}`}>
      <label className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-white/30 bg-white/10 hover:bg-white/20">
        <div className="flex flex-col items-center justify-center pb-6 pt-5">
          <svg className="mb-4 h-8 w-8 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
          </svg>
          <p className="mb-2 text-sm text-white">
            <span className="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p className="text-xs text-white/60">PNG, JPG, JPEG or WEBP</p>
        </div>
        <input 
          type="file" 
          className="hidden" 
          accept="image/*"
          onChange={onFileChange}
        />
      </label>
    </div>
  );
}

export default ImageUploader;
