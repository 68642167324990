import React, { useState } from 'react';
import StyleSelector from '../components/StyleSelector';
import ImageUploader from '../components/ImageUploader';
import Loader from '../components/Loader';

function HomePage() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [coveringStyle, setCoveringStyle] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedImage(file);
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleStyleChange = (value) => {
    setCoveringStyle(value);
  };

  const handleProcessImage = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('image', uploadedImage);
      formData.append('style', coveringStyle);

      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const response = await fetch(`${apiUrl}/api/process-image`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Image processing failed');
      }

      const result = await response.json();
      console.log('Result:', result);
      setGeneratedImages(result.generatedImages || result.processedImageUrl);
      setError('');
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        stack: error.stack,
        name: error.name
      });
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-2xl text-white">
      <div className="mb-8 text-center">
        <h1 className="text-4xl font-bold tracking-tight">Covered Bridge Generator</h1>
        <p className="mt-2 text-xl text-white/60">Transform your bridge with a realistic cover</p>
      </div>
      
      {error && (
        <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
          {error}
        </div>
      )}

      <div className="mb-6">
        <label className="mb-2 block text-sm font-medium">Upload Bridge Image</label>
        <ImageUploader onFileChange={handleFileChange} />
      </div>

      {previewImage && (
        <div className="mb-6 overflow-hidden rounded-lg bg-white/10 p-4">
          <img
            src={previewImage}
            alt="Uploaded Bridge"
            className="w-full rounded-lg shadow-lg"
          />
        </div>
      )}

      {uploadedImage && (
        <div className="space-y-6">
          <StyleSelector onStyleChange={handleStyleChange} selectedStyle={coveringStyle} />
          <button
            onClick={handleProcessImage}
            disabled={!uploadedImage || !coveringStyle.trim() || isLoading}
            className="w-full rounded-lg bg-brown-700 px-5 py-3 text-center text-sm font-medium text-white hover:bg-brown-800 focus:outline-none focus:ring-4 focus:ring-brown-300 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {isLoading ? 'Generating...' : 'Generate Cover'}
          </button>
        </div>
      )}

      {isLoading && <Loader />}

      {generatedImages.length > 0 && (
        <div className="mt-8 space-y-6">
          <h2 className="text-2xl font-bold text-center">Generated Covers</h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {generatedImages.map((imageUrl, index) => (
              <div key={index} className="relative overflow-hidden rounded-lg bg-white/10 p-4">
                <img
                  src={imageUrl}
                  alt={`Generated Cover ${index + 1}`}
                  className="w-full rounded-lg shadow-lg"
                />
                <a
                  href={imageUrl}
                  download={`covered_bridge_${index + 1}.jpg`}
                  className="absolute bottom-4 right-4 rounded-full bg-green-500 p-2 text-white hover:bg-green-600"
                >
                  <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
                  </svg>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;
